import React, { Fragment } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SEO from '../components/SEO';
import { Content } from '../templates/BlogPost';

const privacyQuery = graphql`
  {
    datoCmsPrivacyPolicy {
      body
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;

export default function Privacy() {
  const data = useStaticQuery(privacyQuery);
  const { seoMetaTags, body } = data.datoCmsPrivacyPolicy;

  return (
    <Fragment>
      <SEO meta={seoMetaTags} />
      <div style={{ background: 'white', padding: 40 }}>
        <div style={{ maxWidth: 1200, margin: 'auto' }}>
          <Content dangerouslySetInnerHTML={{ __html: body }} />
        </div>
      </div>
    </Fragment>
  );
}
